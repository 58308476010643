$paddingTotal: 9px;
$paddingOption: 5px;

.filter-bar {
	display: flex;
	flex-direction: row;

	.cell {
		text-align: center;
		display: flex;
		flex-direction: column;
		border: 0.5px solid white;

		span {
			color: $neutral100;
			line-height: 24px;
			vertical-align: top;
		}

		img {
			height: 20px;
		}

		div {
			padding: $paddingOption;
		}
	}

	.total {

		flex: 1.1;

		span {
			line-height: 24px;
			font-size: 0.8rem;
			font-weight: 500;
		}

		div {
			background-color: $tertiary095;
			color: $neutral100;
			padding-top: $paddingTotal;
			padding-bottom: $paddingTotal;
			cursor: pointer;
			height: 48px;
			line-height: 30px;
		}
	}

	.option {
		flex: 1;

		div {
			margin-top: 29px;
			height: 38px;
			line-height: 30px;
			cursor: pointer;
		}
	}
}
