.badge {
    background-color: $neutral045;
    border-radius: 40% !important;
    width: 20px;
    height: 20px !important;
    text-align: center;
    padding-top: 0 !important;
    padding: 5% 5% !important;
    position: relative;
    right: -18px;
    top: -42px;
    float: right;
    display: block;
}

.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
    z-index: 5000 !important;
}

.cdk-global-scrollblock {
	overflow: hidden;
}
