.grid {
	@include paddings;
	@include margins;

	&--stretch {
		flex: 1;
	}

	&--flex-100 {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}
