.btn {
	&--icon {
		&:disabled {
			opacity: 0.5;
		}

		&-paddings {
			padding: 6px 12px;
		}

		&-24 {
			height: 24px;
			width: 24px;
			padding: 0;
		}

		&-36 {
			height: 36px;
			width: 36px;
			padding: 0;
		}

		&-40 {
			height: 40px;
			width: 40px;
			padding: 0;
		}

		&-flex {
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}

		&-default {
			color: $tertiary030;
			border-radius: 16px;
			border: 1px solid transparent;
			background-color: transparent;

			&:hover {
				color: $tertiary050;
			}

			&:active {
				color: $tertiary020;
			}

			&:disabled {
				color: $tertiary040;
				opacity: 0.5;
				pointer-events: none;
			}
		}

		&-primary {
			color: $primary050;

			&:hover {
				color: $primary050;
			}
		}

		&-primary-hover {
			&:hover {
				color: $primary050;
			}
		}

		&-secondary {
			color: $secondary085;

			&:hover {
				color: $secondary085;
			}
		}

		&-secondary-hover {
			&:hover {
				color: $secondary085;
			}

			&:disabled {
				color: $tertiary080;
			}
		}

		&-caret > i {
			width: 10px;
			color: $secondary085;
			transition: all 0.3s ease;

			&:hover {
				color: $secondary070;
			}
		}
	}
}
