@import "~daterangepicker/daterangepicker.css";

button.date-picker-arrow {
	padding: 0 0.75rem;
}

.data-range-picker-today {
	background-color: rgb(225, 226, 148);
	font-weight: 900;
}

.daterangepicker {
	z-index: 9999;
}