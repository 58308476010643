@import '@shared/colors';
@import '@shared/variables';

.mat-mdc-dialog-container {
    transition: transform 0.3s ease-in-out;
}

.mat-dialog-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 12px 0 0;

    & mat-icon {
        color: $tertiary045;

        &:hover {
            color: $tertiary020;
        }
    }

	&__group {
		margin-bottom: 20px;
	}

	&__title {
		margin: 0 !important;
	}

	&__subtitle {
		display: block;
		line-height: normal;
		color: $tertiary070;
		font-size: 0.8rem;
		font-weight: 400;
		margin-left: 2.3em;
		margin-top: -0.8em;
	}
}

.mat-mdc-dialog-title {
    color: $tertiary045 !important;

    & span {
        font-weight: 700;
    }
}

.mat-mdc-dialog-content {
    display: flex !important;
    flex-direction: column !important;

    & .grid {
        padding-top: 10px;
        margin-bottom: 10px;
    }
}

.mat-dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
}

.mat-dialog-form {
    display: flex;
    flex-direction: column;
}

.mat-dialog-header-with-button {
    display: flex;
    color: $tertiary045;

    & :nth-child(2) {
        margin-top: 6px;
    }
}

mat-dialog-actions.mdc-dialog__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
	padding: 0 24px 9px 24px;

    & > button {
        width: 100%;
    }

    @media screen and (min-width: $navbar-mobile) {
        flex-direction: row;
        justify-content: flex-end;

        & > button {
            width: initial;
        }
    }
}

// Custom dialogs common styles
.custom-dialog {
	.mdc-dialog__content.has-vertical-scrollbar {
		margin-right: 24px;
		margin-bottom: 15px;
	}
}
