.mdc-label {
    margin-bottom: 0;
    margin-left: 4px !important;
    font-size: 16px;
    color: $tertiary045;
    letter-spacing: normal;
}

mat-checkbox .mdc-label {
    margin-left: 0 !important;
}

nav.sub-navbar .mdc-label {
    color: $neutral000;
    font-size: 14px;
}

fitech-workspace-switch-md .mdc-label {
    margin-right: 6px !important;
    font-size: 16px;
    color: $tertiary045;
}

.desc-label .mdc-label {
    font-size: 1.3rem;
    line-height: 1.5rem;
}

.mat-mdc-floating-label {
    font-size: 16px;
}