// Unfortunately below is required since select-theme colors are not passed to mat select options
.mdc-list-item__primary-text {
	--mat-option-selected-state-label-text-color: #{map-get($md-primary-blue, 500)};
}

mat-option.mat-mdc-option.mat-select-search-option {
	position: sticky;
	top: 0;
	z-index: 1;
	opacity: 1;
	pointer-events: all;

	&--no-results {
		height: 6.5em;
	}

	mat-pseudo-checkbox {
		display: none;
	}

	.mdc-list-item__primary-text {
		opacity: 1;
	}
}

div.mat-mdc-select-panel {
	padding: 0 0 8px 0 !important;
}
