@import '@shared/colors';

.box-title {
	display: flex;
	font-size: 1.2rem;
	line-height: 40px;
	padding-left: 16px;
	margin: 10px 20px 20px 20px;
	color: $neutral100;
	background-color: $tertiary030;
	border-left: solid 6px $primary080;
	border-right: solid 40px $primary080;
	@include margins;
	@include paddings;

	&__params {
		font-weight: 500;
		font-size: 90%;
		margin: 0 10px;
	}

	&__spacer {
		flex: 1;
	}

	@media print {
		border-right: none;
	}
}

.desc-label {
	display: flex;
	font-size: 1.3rem;
	margin-top: 10px;
	margin-bottom: 20px;
	padding-left: 8px;
	border-left: solid 6px $primary080;
	@include margins;
	@include paddings;

	&--center {
		display: flex;
		align-items: center;
	}

	&--separate {
		min-width: 130px;
		margin-right: 20px;
		display: flex;
		flex-direction: column;
		margin-top: 4px;
		margin-bottom: 4px;
	}

	&--stretch {
		flex: 1;
	}

	&__label {
		font-size: 60%;
	}

	&__value {
		font-size: 80%;
	}
}

.text {
	&--error {
		color: $statusError;
	}
}
