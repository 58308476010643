@media (-webkit-min-device-pixel-ratio: 1.25) {
	.navbar {
		font-size: 13px !important;
		line-height: 1.3;
	}
}

body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
	margin-right: 0 !important;
}
