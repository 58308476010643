.btn {
	&--link {
		color: $primary050;
		font-size: 14px;
		line-height: inherit;
		padding: 0 5px;
		border: 1px solid transparent;

		&:hover {
			color: $primary060;
			text-decoration: underline;
		}

		&:active {
			color: $primary040;
		}

		&-big {
			padding: 6px 12px;
		}

		&-selected {
			color: $primary040;
			border: 1px solid $primary040;
		}

		&-disabled {
			color: $tertiary030;
			text-decoration: none;
			pointer-events: none;
		}
	}

	&--primary-reverse {
		border: 1px solid $primary050;
		color: $primary050;
		padding: 6px 12px;

		&:hover {
			color: $neutral100;
			background-color: $primary050;
		}

		&:active {
			color: $neutral100;
			background-color: $primary040;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}