.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px $neutral100;
}

.datepicker i.fa-calendar-alt {
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        color: $secondary080;
    }
}