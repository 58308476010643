.mat-mdc-snack-bar-container {
	--mdc-snackbar-supporting-text-color: #{map-get($md-primary-orange, A100)};
	--mat-snack-bar-button-color: #{map-get($md-primary-orange, A100)};
	margin: 24px !important;
}

.error-snackbar {
	--mdc-snackbar-container-color: #{map-get($md-other, statusErrorDark)};
}

.success-snackbar {
	--mdc-snackbar-container-color: #{map-get($md-other, statusOk)};
}

.info-snackbar {
	--mdc-snackbar-container-color: #{map-get($md-other, primary060)};
}

.error-snackbar,
.success-snackbar,
.info-snackbar {
	& button {
		font-weight: bold;
	}
}