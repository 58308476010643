@import '@shared/variables';

.nav-tabs {
	margin-right: 4px;

	.nav-link.active {
		background-color: $primary050;
		color: $neutral100;
		font-size: 0.8rem;
	}

	.nav-link {
		border: none;
		margin-left: 2px;
		background-color: $primary080;
		color: $neutral100;
		font-size: 0.8rem;
		padding: 0.3rem 1rem;
	}
}

.card-with-tabs {
	border-top: none;
	margin-left: 2px;
	margin-right: 4px;
	border-radius: 0;
	margin-bottom: 5px;
}

.card-with-tabs {
	.card-body {
		padding: 15px 15px;

		@media screen and (max-width: $md) {
			padding: 15px 5px;
		}
	}
}
