.selectiongroup,
.mydrp .selection,
.selbtngroup {
	height: 24px !important;
}

.mydrp .btnpicker {
	border-radius: 0 12px 12px 0 !important;
}

.mydrp,
.mydrp .headertodaybtn,
.mydrp .selection,
.mydrp .selectiongroup,
.mydrp .selector {
	border-radius: 12px !important;
}

.mydrp .caltable,
.mydrp .monthtable,
.mydrp .yeartable {
	border-radius: 0 0 12px 12px !important;
}

.mydrp .header,
.mydrp .titlearea,
.mydrp .titleareatxt {
	border-radius: 12px 12px 0 0 !important;
}
