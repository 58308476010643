@mixin distance-values($name, $property) {
	&--#{$name}-auto {
		#{$property}: auto;
	}

	&--#{$name}-0 {
		#{$property}: 0;
	}

	&--#{$name}-1 {
		#{$property}: 1px;
	}

	&--#{$name}-2 {
		#{$property}: 2px;
	}

	&--#{$name}-3 {
		#{$property}: 3px;
	}

	&--#{$name}-4 {
		#{$property}: 4px;
	}

	&--#{$name}-5 {
		#{$property}: 5px;
	}

	&--#{$name}-6 {
		#{$property}: 6px;
	}

	&--#{$name}-8 {
		#{$property}: 8px;
	}

	&--#{$name}-10 {
		#{$property}: 10px;
	}

	&--#{$name}-12 {
		#{$property}: 12px;
	}

	&--#{$name}-14 {
		#{$property}: 14px;
	}

	&--#{$name}-15 {
		#{$property}: 15px;
	}

	&--#{$name}-16 {
		#{$property}: 16px;
	}

	&--#{$name}-20 {
		#{$property}: 20px;
	}

	&--#{$name}-32 {
		#{$property}: 32px;
	}

	&--#{$name}-64 {
		#{$property}: 64px;
	}
}
