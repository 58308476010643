@mixin flex() {
	&--flex {
		display: flex;

		&-center {
			align-items: center;
			justify-content: center;
		}
	}

	&--flex-1 {
		flex: 1;
	}
}
