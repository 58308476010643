ngb-popover-window {
	width: auto;
	max-width: none !important;
	height: auto;
	max-height: 720px;
	font-size: 16px;
	z-index: 1001;

	& .popover-body {
		padding: 0.5rem 0.75rem;
	}
}

.popover {
	&--p-0 {
		& .popover-body {
			padding: 0;
		}
	}

	&-close {
		position: absolute;
		top: 8px;
		right: 8px;

		&--closer {
			top: 3px;
			right: 7px;
		}
	}
}
