.mat-expansion-panel {
	&-header {
		font-size: 15px;

		&.mat-expanded {
			height: unset;
			min-height: 64px;
		}

		&[aria-disabled='true'] {
			color: $neutral050;
		}

		&.cdk-keyboard-focused:not([aria-disabled='true']) {
			background: $neutral010;
		}

		&.cdk-program-focused:not([aria-disabled='true']) {
			background: $neutral010;
		}

		&-title {
			color: $neutral010;
			font-weight: 700;

			&.break-text {
				overflow-wrap: anywhere;
			}
		}

		&-description {
			color: $neutral050;
			font-size: 14px;

			&.right-aligned {
				flex-grow: 0;
			}
		}
	}

	&-content {
		font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
	}

	&:not([class*='mat-elevation-z']) {
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	}
}
