@import '@shared/colors';
//@import "~bootstrap/dist/css/bootstrap.css";

// This is imported from scss module by module, because of need to
// skip following styles:
// - buttons
// - reboot

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

// Overrides
// .modal-header {

// 	h1,
// 	h2,
// 	h3,
// 	h4,
// 	h5,
// 	h6 {
// 		margin: 0;
// 	}
// }

.dropdown-box {
	margin-left: 5px;
	margin-right: 5px;
	width: 250px;

	.dropdown .btn {
		z-index: 1000;
		position: relative;
		margin: auto;
		width: 250px;
		border-radius: 4px;
		background-color: $secondary085;
		color: $tertiary030;
		font-weight: 600;
		padding: 0.18rem 0.5rem;
	}

	.dropdown-menu {
		width: 250px;
		max-height: 500px;
		overflow-y: auto;
	}

	.dropdown-item {
		padding: 0.25 rem 0.75rem;

		&:active {
			background-color: $secondary085;
		}
	}

	.selected {
		font-weight: bold;
	}

	.icon {
		color: rgb(218, 218, 218);
		padding-right: 3px;
	}
}