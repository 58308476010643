@mixin double-distance-values($name, $property1, $property2) {
	&--#{$name}-auto {
		#{$property1}: auto;
		#{$property2}: auto;
	}

	&--#{$name}-0 {
		#{$property1}: 0;
		#{$property2}: 0;
	}

	&--#{$name}-1 {
		#{$property1}: 1px;
		#{$property2}: 1px;
	}

	&--#{$name}-2 {
		#{$property1}: 2px;
		#{$property2}: 2px;
	}

	&--#{$name}-3 {
		#{$property1}: 3px;
		#{$property2}: 3px;
	}

	&--#{$name}-4 {
		#{$property1}: 4px;
		#{$property2}: 4px;
	}

	&--#{$name}-5 {
		#{$property1}: 5px;
		#{$property2}: 5px;
	}

	&--#{$name}-8 {
		#{$property1}: 8px;
		#{$property2}: 8px;
	}

	&--#{$name}-10 {
		#{$property1}: 10px;
		#{$property2}: 10px;
	}

	&--#{$name}-12 {
		#{$property1}: 12px;
		#{$property2}: 12px;
	}

	&--#{$name}-14 {
		#{$property1}: 14px;
		#{$property2}: 14px;
	}

	&--#{$name}-15 {
		#{$property1}: 15px;
		#{$property2}: 15px;
	}

	&--#{$name}-16 {
		#{$property1}: 16px;
		#{$property2}: 16px;
	}

	&--#{$name}-20 {
		#{$property1}: 20px;
		#{$property2}: 20px;
	}

	&--#{$name}-32 {
		#{$property1}: 32px;
		#{$property2}: 32px;
	}

	&--#{$name}-64 {
		#{$property1}: 64px;
		#{$property2}: 64px;
	}
}
