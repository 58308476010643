@import '@shared/colors';

.report-base {
	display: flex;
	height: 100%;
	overflow-x: hidden;
	padding: 10px 10px 10px 20px;

	@include paddings;
	@include margins;

	@media print {
		max-width: 1000px;
	}

	&__message {
		width: 100%;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $neutral100;
		font-size: 2em;
		color: $neutral070;
		font-weight: 700;
		z-index: 999;
		margin: -10px -10px -10px -20px;

		&--m-0 {
			margin: 0;
		}
	}
}
