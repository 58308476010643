.grid-tooltip,
.text-tooltip {
	font-size: $font-size-grid;
	opacity: 1 !important;
	padding: 0.2rem;
}

.marker-tooltip {
	font-size: 0.7rem;
	opacity: 1 !important;
	width: auto;
	z-index: 500;

	hr {
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
	}
}

.grid-tooltip-inner,
.marker-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.text-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.filter-tooltip {
	font-size: 0.7rem;
	opacity: 1 !important;
	padding: 0.2rem;
}

.filter-tooltip-inner {
	border-radius: 0;
	background-color: $tertiary020;
	color: $neutral100;
}

.chart-tooltip-desc {
	color: $neutral050;
}

.chart-tooltip {
	font-size: 0.8em;
}


.tooltip-chart {
	font-size: 0.9em;

	&__title {
		color: $tertiary020;
		margin-bottom: 5px;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		&-circle {
			height: 15px;
			width: 15px;
			background-color: yellow;
			border-radius: 50%;
			display: inline-block;
			margin-right: 5px;

			&-wrapper {
				display: flex;
				align-items: center;
			}
		}

		&-title {
			font-weight: 400;
			margin-right: 20px;
		}

		&-value {
			font-weight: 600;
		}
	}
}
