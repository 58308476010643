textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
label:focus,
.form-control:focus {
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}
