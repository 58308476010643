mat-slide-toggle {
	.mdc-switch {
		&__track::after,&__track::before {
			box-shadow: inset 0px 0px 2px 0.8px $tertiary030;
			border: 0.5px solid $tertiary025
		}

		&__handle::before,&__handle::after {
			box-shadow: 0px 0px 1px 1.1px $tertiary025;
		}

		&__icon {
			transform: scale(0.8);
		}

		&__ripple {
			transform: translate(-50%, -50%) scale(0.8);
		}

		&--disabled {
			& .mdc-switch__handle::before,& .mdc-switch__handle::after {
				border: initial;
			}
		}
	}

	&.mat-primary {
		--mdc-switch-selected-icon-color: #{$tertiary030};
	}
}
