.main-container {
	display: flex;
	height: calc(100vh - 54px);
	flex-direction: column;

	&--p-8 {
		padding: 8px;
	}

	&--no-height {
		height: unset;
	}
}
