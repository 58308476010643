@mixin flex-container {
	display: flex;
	height: 100%;
	width: 100%;

	&--column {
		flex-direction: column;
	}

	&__child {
		&--remaining {
			flex-grow: 1;
		}
	}
}
