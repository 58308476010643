@import '@shared/mixins/containers';

.flexbox {
	@include flex-container;
}

.content-panel {
	@include flex-container;
}

.flex-child-remaing {
	flex-grow: 1;
}


// TODO: check usage
.column-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.row-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.container-fluid {
	padding-right: 0;
	padding-left: 0;
}

