.mat-mdc-button.mat-primary[disabled],
.mat-button.mat-accent[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-mdc-icon-button.mat-primary[disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-mdc-outlined-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26) !important;
}

.mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-label-text-color: #{map-get($md-other, primary040)};
}

.mat-mdc-outlined-button>.mat-icon {
    margin: 0 !important;
}

.mat-mdc-outlined-button.mat-primary {
    --mat-mdc-button-persistent-ripple-color: #{map-get($md-other, tertiary060)};
    --mat-mdc-button-ripple-color: #{map-get($md-other, lighterTertiary060)};
}
