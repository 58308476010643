@import '@shared/colors';

.icon {
	@include rotates;
	@include paddings;
	@include margins;
	@include flex;
	@include sizes;

	&--active-color {
		color: $secondary085;
	}

	&--fs-1r {
		font-size: 1rem;
	}

	&--fs-045r {
		font-size: 0.45rem;
	}

	&--fs-095r {
		font-size: 0.95rem;
	}

	&--refreshing {
		font-size: 2em;
		color: $tertiary070;
		font-weight: 700;
	}
}