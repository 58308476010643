$color-default11: #1a73e833;
$color-map-selection10: rgba(100, 98, 224, 0.8);

$color-notedited: #1e4d8ac7;
$color-notedited-group: #1a73e8;
$color-notedited-back: rgba(26, 115, 232, 0.537);

$color-edited: rgba(219, 184, 5, 0.78);
$color-edited-group: #dbb705;
$color-edited-back: rgba(221, 186, 8, 0.525);

$color-active: rgba(141, 201, 29, 0.824);
$color-active-group: rgba(131, 185, 30, 0.647);
$color-active-back: rgba(141, 201, 29, 0.373);

$color-inactive: rgba(155, 155, 155, 0.8);
$color-inactive-group: rgba(143, 143, 143, 0.6);
$color-inactive-back: rgba(184, 184, 184, 0.599);

$color-alarm-low: rgba(134, 98, 224, 0.8);
$color-alarm-low-group: rgba(134, 98, 224, 0.68);
$color-alarm-low-back: rgba(134, 98, 224, 0.537);

$color-alarm-high: rgba(220, 53, 70, 0.906);
$color-alarm-high-group: rgba(220, 53, 70, 0.686);
$color-alarm-high-back: rgba(220, 56, 72, 0.522);


.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
	-webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
	-moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
	-o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	-webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
	-moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
	-o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.sensor-detail {
	display: inline-flex;
	flex-direction: row;
	padding: 0 12px 0 28px !important;
	height: 30px !important;
	font-size: 14px !important;
	color: white;
	line-height: 30px !important;
}

.sensor-detail-long {
	min-width: 150px;
}

.sensor-detail-icon {
	padding: 0.6em;
	background-color: #fff;
	color: #6a6a6a;
	margin: 0 4px 0 -28px;
	height: 31px;
	width: 31px;
	border-radius: 15px;
}

.sensor-detail-container {
	display: flex;
	flex-direction: column;
	background-color: transparent !important;
	margin-top: -3px;
	white-space: nowrap;

	.two-rows-line {
		height: 15px;
		line-height: 15px;
	}
}

.sensor-alarmed {
	background-color: #dc3545 !important;
}

// marker for single tracker
.my-custom-icon {
	width: 40px !important;
	max-width: 160px !important;
	height: 40px !important;
	margin-left: -20px;
	margin-top: -20px;
	border-radius: 20px;
	border-color: $color-default11;
	text-align: center;

	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;

	outline: none !important;
}

.my-custom-icon div {
	height: 40px;
	text-align: center;
	border-radius: 20px;
	padding-top: 3px;
	font: 10px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.custom-point-icon div {
	color: $neutral100;
	font-size: 20px !important;
	margin-top: -5px;
	margin-left: -5px;
}

.my-custom-icon span {
	line-height: 30px;
}

.leaflet-popup-tip-container {
	margin-left: -5px !important;
}

.marker-tooltip-dateTop {
	font-size: 0.9rem;
}

.marker-tooltip-dateBottom {
	color: $neutral080;
}

.marker-selected {
	box-shadow: 0 0 0 6px $color-map-selection10;
	border-radius: 50%;
	width: 30px !important;
	height: 30px !important;
}

.cluster-svg.marker-selected {
	width: 42px !important;
	height: 42px !important;
}

.broadcast-tower {
	font-size: 10px;
	position: absolute;
	margin-top: -2px;
	margin-left: 10px;
	opacity: 0.6;
}

.hidden {
	visibility: hidden;
}

.leaflet-touch .leaflet-control-layers-toggle {
	width: 30px;
	height: 30px;
}

.marker-cluster-small {
	width: 42px !important;
	height: 42px !important;
	border-radius: 50% !important;
	text-align: center;
}

// marker for group
.marker-cluster-small,
.marker-cluster-medium {
	background-color: rgba(255, 255, 255, 0.701) !important;
}

.item-notedited {
	background-color: $color-notedited !important;
}

.marker-notedited {
	background-color: $color-notedited-back !important;

	div {
		background-color: $color-notedited-group !important;
	}
}

.item-edited {
	background-color: $color-edited !important;
}

.marker-edited {
	background-color: $color-edited-back !important;

	div {
		background-color: $color-edited-group !important;
	}
}

.item-active {
	background-color: $color-active !important;
}

.marker-active {
	background-color: $color-active-back !important;

	div {
		background-color: $color-active-group !important;
	}
}

.item-inactive {
	background-color: $color-inactive !important;
}

.marker-inactive {
	background-color: $color-inactive-back !important;

	div {
		background-color: $color-inactive-group !important;
	}
}

.item-alarm-high {
	background-color: $color-alarm-high !important;
}

.marker-alarm-high {
	background-color: $color-alarm-high-back !important;

	div {
		background-color: $color-alarm-high-group !important;
	}
}

.item-alarm-low {
	background-color: $color-alarm-low !important;
}

.marker-alarm-low {
	background-color: $color-alarm-low-back !important;

	div {
		background-color: $color-alarm-low-group !important;
	}
}

.pie-container {
	width: 42x;
	height: 42px;
	position: relative;
}

.pie-svg {
	width: 42px;
	height: 42px;
	border-radius: 50%;
}

.pie-text {
	position: absolute;
	width: 22px;
	height: 22px;
	border-radius: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	top: 10px;
	left: 10px;
	color: black;
	line-height: 22px;
	text-align: center;
}
