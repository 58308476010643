a {
	color: $neutral100;
	text-decoration: none;

	&:hover {
		color: $neutral080;
		text-decoration: none;
	}
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}
