fitech-workspace-login,
fitech-workspace-login-request-for-roles,
fitech-workspace-password-forgot {
	#auth-box {
		background: #42454a;
	}

	.box {
		background-color: $secondary085;
	}
}
