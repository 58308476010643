html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	width: 100%;
	height: 100%;
}

body {
	margin: 0;
	width: 100%;
	height: 100%;
	line-height: 1.5;
	color: $neutral040;
	text-align: left;
	background-color: $neutral100;
	@include default-font;
}

main {
	width: 100%;
	padding: 0;
}

@media print {
	* {
		overflow: visible !important;
	}

	.main-container {
		page-break-after: always;
		height: auto !important;
	}

	html,
	body {
		height: auto !important;
	}
}

@media (-webkit-min-device-pixel-ratio: 1.25) {

	body,
	html {
		font-size: 14px;
		line-height: 1;
	}
}
