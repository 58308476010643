@import '../../abstract/index';

.btn {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 0;
	color: inherit;
	background-color: transparent;
	background-image: none;
	border: 0;
	border-radius: 4px;
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	line-height: 1.5;
	@include default-font;
	@include rotates;
	@include paddings;
	@include margins;
	@include flex;
	@include sizes;

	&:disabled {
		cursor: default;
	}

	&:hover {
		color: inherit;
		text-decoration: none;
	}

	&:active {
		outline: 0;
		box-shadow: none;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&--block {
		display: block;
		width: 100%;
	}

	&--p-default {
		padding: 6px 12px;
	}

	&--fs-14 {
		font-size: 14px;
	}

	&--fs-16 {
		font-size: 16px;
	}

	&--fs-18 {
		font-size: 18px;
	}

	&--fs-20 {
		font-size: 20px;
	}

	&--border-transparent {
		border: 1px solid transparent;
	}

	&--w-100 {
		width: 100%;
	}
}
