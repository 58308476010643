#selectType {
	height: 24px !important;
	padding: 0 0.75rem;
	font-size: 0.8rem;
	line-height: 1;
	border-radius: 12px;
}

.select-type {
	height: 24px !important;
	padding: 0 0.75rem;
	font-size: 0.8rem;
	line-height: 1;
	border-radius: 12px;
}
